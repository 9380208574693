class OkEvent {
    constructor() {
        this._init();
    }

    /**
     * @protected
     */
    _init() {
        this.list = [];
    }

    /**
     * @param {Function} handler
     * @param {*} handlerContext
     * @param {*} eventArgs
     * @return {OkEvent}
     * @protected
     */
    _callHandler(handler, handlerContext, eventArgs) {
        handler.call(handlerContext, eventArgs);
        return this;
    }

    /**
     * @param {*} [eventArgs]
     * @return {OkEvent}
     */
    trigger(eventArgs) {
        var self = this,
            invocationList = self.list.slice(),
            //use eventArg2 for extends _callHandler, not copy args because https://github.com/petkaantonov/bluebird/wiki/Optimization-killers#3-managing-arguments
            eventArg2 = arguments[1],
            onceList = [];

        for (var i = 0, l = invocationList.length; i < l; i++) {
            var invocationItem = invocationList[i];
            // fix for attach once. TODO add correct processing add and remove handler at trigger processing time
            if (invocationItem) {
                if (invocationItem.once) {
                    onceList.push(invocationItem);
                }
                // noinspection JSCheckFunctionSignatures
                self._callHandler(invocationItem.handler, invocationItem.context, eventArgs, eventArg2);
            }
        }

        onceList.forEach(function(onceItem) {
            var i = self.list.indexOf(onceItem);
            if (i !== -1) {
                self.list.splice(i, 1);
            }
        });

        return self;
    }

    /**
     *
     * @param {Function} handler
     * @param {Object} [context]
     * @return {OkEvent}
     */
    attach(handler, context) {
        var self = this;

        self.list.push(wrapMethod(context, handler));

        return self;
    }

    /**
     *
     * @param {Function} handler
     * @param {Object} [context]
     * @return {OkEvent}
     */
    attachOnce(handler, context) {
        var self = this,
            invocationItem = wrapMethod(context, handler);

        invocationItem.once = true;
        self.list.push(invocationItem);

        return self;
    }

    /**
     *
     * @param {Function} handler
     * @param {Object} [context]
     * @return {OkEvent}
     */
    detach(handler, context) {
        var self = this,
            list = self.list,
            i = list.length;

        while(i-- > 0) {
            var invocationItem = list[i];
            if (invocationItem.handler === handler && invocationItem.context === context) {
                list.splice(i, 1);
            }
        }

        return self;
    }

    /**
     *
     * @return {boolean}
     */
    hasHandlers() {
        return this.list.length !== 0;
    }

    /**
     *
     * @param {Boolean} isNeedAttach
     * @param {Function} handler
     * @param {Object} [context]
     * @return {OkEvent}
     */
    switchListener(isNeedAttach, handler, context) {
        return isNeedAttach
            ? this.attach(handler, context)
            : this.detach(handler, context);
    }
}

/**
 *
 * @param {Object} context
 * @param {Function} handler
 * @return {{context: *, handler: *}}
 */
function wrapMethod(context, handler) {
    return {
        context: context,
        handler: handler,
        once: false
    };
}

/**
 * @module OK/EventFactory
 */
function create() {
    return new OkEvent();
}

export default { OkEvent, create };

export { OkEvent, create };
